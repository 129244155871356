import React from 'react';
import { useThemeContext } from '@crema/context/ThemeContextProvider';
import { alpha, Box } from '@mui/material';
// import { ReactComponent as Logo } from '../../../../assets/icon/logo.svg';
import { ReactComponent as Logo } from '../../../../assets/icon/logo-white.svg';
// import { ReactComponent as LogoText } from '../../../../assets/icon/logo_text.svg';
import { ReactComponent as LogoText } from '../../../../assets/icon/logo_text_white.svg';
import { useRouter } from 'next/router';
import { initialUrl } from '@crema/constants/AppConst';
import styled from '@emotion/styled';

type AppLogoProps = {
  color?: string;
  textHide?: boolean;
  redirectUrl?: string;
};

const AppLogo: React.FC<AppLogoProps> = ({
  color,
  textHide = true,
  redirectUrl = initialUrl,
}) => {
  // const { theme } = useThemeContext();
  const router = useRouter();

  const fillColor = color ? color : '#2336A5';

  const StyledLogoText = styled(LogoText)(() => {
    return {
      '& path': {
        fill: fillColor,
      },
    };
  });

  return (
    <Box
      sx={{
        height: { xs: 56, sm: 70 },
        padding: 2.5,
        display: 'flex',
        flexDirection: 'row',
        cursor: 'pointer',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          height: { xs: 40, sm: 45 },
          width: 'auto',
        },
      }}
      className="app-logo"
      onClick={() => {
        router.push(redirectUrl);
      }}
    >
      <Logo fill={fillColor} style={{ maxHeight: 45 }} />
      <Box
        component="span"
        sx={{
          mt: 1,
          ml: 2,
          displayPrint: 'block',
          display: { xs: textHide ? 'none' : 'block', sm: 'block' },
          '& svg': {
            height: { xs: 25, sm: 30 },
          },
        }}
      >
        <StyledLogoText style={{ maxHeight: 30 }} />
      </Box>
    </Box>
  );
};

export default AppLogo;
