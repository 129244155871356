import React, { useEffect } from 'react';
// import { useRouter } from 'next/router';
// import { useAuthUser } from '@crema/hooks/AuthHooks';
// import AppComponentLoader from 'libs/components/src/lib/AppComponentLoader';

// eslint-disable-next-line react/display-name,@typescript-eslint/no-explicit-any
const withData = (ComposedComponent: any) => (props: any) => {
  // const { user, isLoading, authIsRequired, isForceValidation } = useAuthUser();
  // const router = useRouter();
  // console.log('withData user', ComposedComponent.name, props, user);

  // useEffect(() => {
  //   // router.asPath is not final on first render
  //   if (!router.isReady) return;
  //
  //   if (authIsRequired && !user && !isLoading) {
  //     const afterQueryParams = 'return_to=' + router.asPath;
  //
  //     router.push('/signin' + (afterQueryParams ? '?' + afterQueryParams : ''));
  //   }
  // }, [user, isLoading, router.isReady, router.asPath, authIsRequired]);
  //
  //
  // if (user && isForceValidation && authIsRequired) {
  //   router.push(`/email-verify/failed/force?id=${user.id}&hash=${user.hash_validation}`);
  //   return;
  // }
  //
  // if (authIsRequired) {
  //   if (!user || isLoading) return <AppComponentLoader />;
  // }
  //
  // if (!router.isReady) {
  //   return <AppComponentLoader />;
  // }

  return <ComposedComponent {...props} />;
};
export default withData;
