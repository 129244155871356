import { useRouter } from 'next/router';
import { Nav, Navbar } from 'react-bootstrap';
// import AppLogo from '@crema/components/AppLogo';

type Props = {
  productRef: React.RefObject<HTMLElement>;
  aboutRef: React.RefObject<HTMLElement>;
};

const MainMenu = (props: Props) => {
  const router = useRouter();

  const isActive = (link: string) => {
    return router.pathname.replace('/[id]', '') === link.replace(/\/\d+$/, '');
  };

  return (
    <>
      <Navbar expand="lg">
        {/*<Container>*/}
        {/*<Navbar.Brand href="#home">*/}
        {/*<AppLogo color="#FFF" />*/}
        {/*</Navbar.Brand>*/}
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto text-nowrap">
            <Nav.Link
              href="/#product"
              active={isActive('/#product')}
              onClick={(event) => {
                if (isActive('/')) {
                  event.preventDefault();
                }

                if (props.productRef.current) {
                  (props.productRef.current as any).scrollIntoView({
                    block: 'center',
                    inline: 'nearest',
                    behavior: 'smooth',
                  });
                }
              }}
            >
              Product
            </Nav.Link>
            {/*<Nav.Link href="/pricing" active={isActive('/pricing')}>*/}
            {/*  Pricing*/}
            {/*</Nav.Link>*/}
            <Nav.Link href="/learning-hub" active={isActive('/learning-hub')}>
              Learning hub
            </Nav.Link>
            <Nav.Link
              href="/#about-us"
              active={isActive('/#about-us')}
              onClick={(event) => {
                if (isActive('/')) {
                  event.preventDefault();
                }

                if (props.aboutRef.current) {
                  (props.aboutRef.current as any).scrollIntoView({
                    block: 'center',
                    inline: 'nearest',
                    behavior: 'smooth',
                  });
                }
              }}
            >
              About
            </Nav.Link>
            <Nav.Link href="/contact-us" active={isActive('/contact-us')}>
              Contact
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        {/*</Container>*/}
      </Navbar>
    </>
  );
};

export default MainMenu;
